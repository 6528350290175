<template>
  <div class="card card-custom gutter-b form">
    <div class="card-body">
      <h4
        class="font-weight-bolder text-dark mb-2 d-flex align-items-center justify-content-between"
      >
        Bio
        <b-button
          size="sm"
          variant="outline-info"
          class="px-2 ml-3"
          @click="showEditForm"
          v-if="!editForm"
          ><i class="fas fa-pen px-0"></i
        ></b-button>
      </h4>
      <template v-if="editForm">
        <textarea
          class="form-control form__input"
          :class="{
            'form__input--error': $v.bio.$dirty && $v.bio.$error,
          }"
          placeholder="Enter Talent Bio"
          ref="talent-bio"
          rows="4"
          v-model="bio"
          @blur="$v.bio.$touch()"
        ></textarea>
        <VulidateError
          :validationField="$v.bio"
          :params="[
            {
              maxLength: {
                number: $v.bio.$params.maxLength.max,
              },
            },
          ]"
        />
        <div class="d-flex mt-4">
          <button
            class="btn btn-info font-weight-bolder px-5"
            @click="saveBio"
            :disabled="isLoading"
            :class="{
              'spinner spinner-light spinner-right': isLoading,
            }"
          >
            <span class="px-4">Save</span>
          </button>
          <button
            class="btn btn-secondary font-weight-bolder px-5 ml-5"
            @click="resetEditForm"
            :disabled="isLoading"
          >
            Cancel
          </button>
        </div>
      </template>
      <template v-else>
        <pre v-if="profile.basicInfo.bio" class="mb-0 font-size-lg">{{
          profile.basicInfo.bio.trim()
        }}</pre>
        <h6 class="text-muted mb-0" v-else>No Data</h6>
      </template>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength } from "vuelidate/lib/validators";
import VulidateError from "@/components/shared/vulidateError.vue";
export default {
  name: "TalentBio",
  mixins: [validationMixin],
  components: { VulidateError },
  props: {
    profile: {
      required: true,
      type: Object,
    },
    profileId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      editForm: false,
      bio: null,
      isLoading: false,
    };
  },
  validations: {
    bio: {
      maxLength: maxLength(1000),
    },
  },
  methods: {
    initBio() {
      this.bio = this.profile?.basicInfo?.bio || null;
    },
    showEditForm() {
      this.initBio();
      this.editForm = true;
      this.$nextTick(() => {
        this.$refs["talent-bio"].focus();
      });
    },
    resetEditForm() {
      this.editForm = this.isLoading = false;
      this.$v.$reset();
    },
    saveBio() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let payload = {
        bio: this.bio,
        talent_id: this.profileId,
      };
      this.isLoading = true;
      this.$store
        .dispatch("talentProfile/editTalentBio", payload)
        .finally(() => {
          this.resetEditForm();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
  font-family: inherit;
}
</style>

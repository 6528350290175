<template>
  <v-dialog v-model="dialog" persistent max-width="700px" @input="initData">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <div class="modal__heading">
        <h2
          class="h1 text-info d-flex align-items-center justify-content-between"
        >
          Select Skills

          <b-button
            @click="
              selectedSkills.push({
                id: null,
                months: 0,
                name: null,
                years: 0,
                justAdded: true,
              })
            "
            size="sm"
            :disabled="
              !skillsListComputed.filter((skill) => !skill.disable).length
            "
            variant="outline-info"
            class="px-2 ml-3"
            ><i class="fas fa-plus-circle px-0"></i
          ></b-button>
        </h2>

        <hr />
      </div>

      <h6 class="text-muted mb-0" v-if="!selectedSkills.length">No Data</h6>

      <form class="form pt-3" @submit.prevent="submitForm">
        <div
          class="form-group row selectedSkills-row"
          v-for="(skill, i) in selectedSkills"
          :key="i"
        >
          <div class="col-5">
            <div class="skills-list">
              <v-autocomplete
                ref="skills-list"
                :items="skillsListComputed"
                item-disabled="disable"
                v-model="skill.id"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                chips
                dense
                small-chips
                label="Skills"
                :menu-props="{}"
                :search-input.sync="skillsSearch"
                @change="skillsSearch = ''"
                @keydown="
                  (skill.id = null),
                    (skill.justAdded = true),
                    (skill.name = null)
                "
              >
                <template v-slot:label>
                  Skills <span class="text-danger">*</span>
                </template>
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    color="white"
                    text-color="black"
                    v-b-tooltip.hover="data.item.name"
                    v-text="
                      `${
                        data.item.name.length >= 18
                          ? data.item.name.substring(0, 18) + '...'
                          : data.item.name
                      }`
                    "
                  >
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      v-b-tooltip.hover="item.name"
                      v-text="
                        `${
                          item.name.length >= 18
                            ? item.name.substring(0, 18) + '...'
                            : item.name
                        }`
                      "
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <div
                class="form__error-label"
                v-if="skill.id == null && isSubmitted"
              >
                Please select the skill
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="">
              <v-select
                :items="Array.from({ length: 50 }, (_, i) => i + 1)"
                outlined
                dense
                hide-details
                label="Years"
                v-model="skill.years"
                clearable
              ></v-select>

              <!--               <v-select
                outlined
                hide-details
                deletable-chips
                v-model="skill.years"
                chips
                small-chips
                :items="Array.from({ length: 50 }, (_, i) => i + 1)"
                label="Years"
              >
                <template v-slot:selection="{ attr, on, item, selected }">
                  <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    color="grey lighten-2"
                    v-on="on"
                    class="mb-1"
                    close
                    @click:close="skill.years = 0"
                  >
                    <span v-text="item"></span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="`${item}`"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select> -->
            </div>
          </div>

          <div class="col-3">
            <div class="">
              <v-select
                v-model="skill.months"
                :items="Array.from({ length: 11 }, (_, i) => i + 1)"
                outlined
                dense
                hide-details
                label="Months"
                clearable
              ></v-select>
            </div>
          </div>

          <div class="col-1">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                @click="deleteSkills(skill.id, i)"
                style="height: 45px; width: 50px"
                variant="outline"
                class="btn btn-secondary font-weight-bolder font-size-h6 px-2 ml-3"
                ><i
                  class="fas fa-plus-circle px-0"
                  style="transform: rotate(45deg)"
                ></i
              ></b-button>
            </div>
          </div>
        </div>
        <div class="text-right mt-5">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': loading,
            }"
            :disabled="loading || !selectedSkills.length"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SkillsFormModal",
  props: {
    talentRole: {
      required: true,
    },
    talentSkills: {
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      skillsList: [],
      loadingSkills: false,
      selectedSkills: [],
      isSubmitted: false,
      skillsSearch: "",
      deletedSkills: [],
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
    }),
    skillsListComputed() {
      let skillsListComputed = [];
      this.skillsList.filter((skill) => {
        if (this.selectedSkills.find((rm) => rm.id === skill.id)) {
          skillsListComputed.push({ ...skill, disable: true });
        } else {
          skillsListComputed.push({ ...skill, disable: false });
        }
      });

      return skillsListComputed;
    },
  },
  methods: {
    ...mapActions({
      getRoleSkills: "talents/getRoleSkills",
      editTalentSkills: "talentProfile/editTalentSkills",
      deleteTalentSkills: "talentProfile/deleteTalentSkills",
    }),
    deleteSkills(index, i) {
      if (index == null) {
        this.selectedSkills = this.selectedSkills.filter(
          (s, sindex) => sindex !== i
        );
      } else {
        if (this.selectedSkills.length > 1) {
          this.deletedSkills = [
            ...this.deletedSkills,
            ...this.selectedSkills.filter(
              (skill) =>
                skill.id == index && skill.id !== null && !skill.justAdded
            ),
          ];
          this.selectedSkills = this.selectedSkills.filter(
            (skill) => skill.id !== index
          );
        } else {
          const deletedSkill = this.talentSkills.find(
            (skill) => skill.id == index
          );
          if (deletedSkill) {
            if (this.deletedSkills.length > 0) {
              let technology_ids = this.deletedSkills.map((skill) => skill.id);

              let payload = {
                technology_id: [...technology_ids, index],
                talent_id: this.$route.params.id,
              };

              this.deleteTalentSkills(payload).then(() => {
                this.deletedSkills = [];
                this.initData();
                this.resetModal();
              });
            } else {
              let payload = {
                technology_id: [index],
                talent_id: this.$route.params.id,
              };

              this.deleteTalentSkills(payload).then(() => {
                this.deletedSkills = [];
                this.initData();
                this.resetModal();
              });
            }
          } else {
            this.selectedSkills = this.selectedSkills.filter(
              (skill) => skill.id !== index
            );
          }
        }
      }
    },
    resetModal() {
      this.deletedSkills = [];
      this.dialog = this.isSubmitted = false;
    },
    initData() {
      if (!this.skillsList.length) {
        this.loadingSkills = true;
        this.getRoleSkills(this.talentRole.id).then((res) => {
          this.skillsList = res;
          this.loadingSkills = false;
        });
      }
      this.selectedSkills = JSON.parse(JSON.stringify([...this.talentSkills]));
    },
    submitForm() {
      this.isSubmitted = true;

      let isFormValid = this.selectedSkills.filter((skill) => {
        if (skill.id == null) return skill;
      });

      let skills = [
        ...this.selectedSkills.map((skill) => {
          let addSkill = {
            skill_id: skill.id,
            years: skill.years,
            months: skill.months,
          };

          if (skill.years == 0 || skill.years == null) delete addSkill.years;

          if (skill.months == 0 || skill.months == null) delete addSkill.months;

          return addSkill;
        }),
      ];
      let payload = {
        skills: skills,
        talent_id: this.$route.params.id,
      };

      if (isFormValid.length == 0) {
        this.editTalentSkills(payload).then(() => {
          this.resetModal();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item__title,
  .v-list-item__subtitle {
    max-width: 620px;
  }
}

.selectedSkills-row {
  margin-bottom: 0;
  margin-top: 0;

  .col-5,
  .col-3,
  .col-1 {
    padding: 12.5px;
  }
}
</style>

<style lang="scss">
.skills-list {
  .v-chip--disabled {
    opacity: 1;
  }
}

.selectedSkills-row {
  .v-text-field--outlined,
  .v-text-field--solo {
    border-radius: 4px;
  }

  .v-text-field--outlined.v-input--dense .v-label {
    top: 10px;
  }

  .v-text-field--outlined .v-label {
    top: 10px;
  }

  .v-text-field--enclosed .v-input__append-inner {
    margin-top: 8px;
  }

  .v-text-field--outlined .v-label--active {
    -webkit-transform: translateY(-24px) scale(0.75);
    transform: translateY(-17px) scale(0.75);
  }

  .v-text-field--filled > .v-input__control > .v-input__slot,
  .v-text-field--full-width > .v-input__control > .v-input__slot,
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 45px;
  }

  .v-chip:hover {
    background: #fff !important;
  }

  .theme--light.v-chip:not(.v-chip--active) {
    background: #fff !important;
    position: absolute;
  }
  .v-chip:before {
    background-color: #fff;
  }
}
</style>

<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <ModalHeading
        :modalTitle="
          formMode === 'add'
            ? 'Nominate Talent To Client'
            : 'Edit Nomination Talent To Client'
        "
      />
      <form class="form pt-3" @submit.prevent="submitForm">
        <div class="row">
          <div class="col-12 mb-7">
            <label class="form__label"
              >Client <span class="text-danger">*</span></label
            >
            <v-autocomplete
              v-model="selectedClient"
              :items="clientsList"
              :loading="isLoading"
              :search-input.sync="searchQuery"
              @update:search-input="debouncedFetchItems(searchQuery)"
              :no-filter="true"
              chips
              outlined
              clearable
              hide-details
              hide-selected
              return-object
              dense
              label="Search with client name, email or squad name"
              @blur="$v.selectedClient.$touch()"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search to select a
                    <strong>Client</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="grey lighten-2"
                  v-on="on"
                >
                  <span
                    v-if="item.project_internal_name"
                    v-text="
                      `${item.client_name} - ${item.project_internal_name}`
                    "
                  ></span>
                  <span v-else v-text="`${item.client_name}`"></span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${item.client_name} - ${item.client_email}`"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.project_internal_name"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <VulidateError
              class="mt-4"
              :validationField="$v.selectedClient"
              :params="['required']"
            />
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <label class="form__label"
                  >Start Date <span class="text-danger">*</span></label
                >

                <v-dialog
                  ref="startDataDialog"
                  v-model="startDataMenu"
                  :return-value.sync="startDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="startDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="startDate"
                    :min="
                      new Date(new Date().setDate(new Date().getDate()))
                        .toISOString()
                        .substr(0, 10)
                    "
                    :max="
                      endData && endData.length > 0
                        ? new Date(
                            new Date(endData).setDate(
                              new Date(endData).getDate() - 1
                            )
                          )
                            .toISOString()
                            .substr(0, 10)
                        : endData
                    "
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDataMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDataDialog.save(startDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <VulidateError
                  :validationField="$v.startDate"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <label class="form__label"
                  >End Date <span class="text-danger">*</span></label
                >
                <v-dialog
                  ref="endDataDialog"
                  v-model="endDataMenu"
                  :return-value.sync="endData"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endData"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endData"
                    :min="
                      startDate
                        ? new Date(
                            new Date(startDate).setDate(
                              new Date(startDate).getDate() + 1
                            )
                          )
                            .toISOString()
                            .substr(0, 10) <
                          new Date(new Date().setDate(new Date().getDate() + 1))
                            .toISOString()
                            .substr(0, 10)
                          ? new Date(
                              new Date().setDate(new Date().getDate() + 1)
                            )
                              .toISOString()
                              .substr(0, 10)
                          : new Date(
                              new Date(startDate).setDate(
                                new Date(startDate).getDate() + 1
                              )
                            )
                              .toISOString()
                              .substr(0, 10)
                        : new Date(new Date().setDate(new Date().getDate() + 1))
                            .toISOString()
                            .substr(0, 10)
                    "
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endDataMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endDataDialog.save(endData)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <VulidateError
                  :validationField="$v.endData"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12 mb-7">
                <label class="form__label"
                  >Select Role <span class="text-danger">*</span></label
                >
                <v-autocomplete
                  v-model="selectedRole"
                  :items="roles"
                  chips
                  clearable
                  hide-details
                  hide-selected
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  @blur="$v.selectedRole.$touch()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Search to select a
                        <strong>Client</strong>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="grey lighten-2"
                      v-on="on"
                    >
                      <span v-text="item.name"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.name}`"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <!--                 <v-select
                  dense
                  v-model="selectedRole"
                  :items="roles"
                  item-text="name"
                  item-value="id"
                  class="v-select-input__contract-type"
                >
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="grey lighten-2"
                      v-on="on"
                      class="mb-1"
                    >
                      <span v-text="item.name"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.name}`"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select> -->
                <VulidateError
                  :validationField="$v.selectedRole"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div
                class="mb-7"
                :class="isFullStack ? 'col-6' : 'col-12'"
                v-if="isFullStack || isFrontend"
              >
                <label class="form__label"
                  >F.E Language <span class="text-danger">*</span></label
                >
                <v-select
                  :items="frontEndLanguages"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                  :disabled="
                    isRolesLoading.frontEndLanguages ||
                    selectedRole === currentRole.id
                  "
                  :loading="isRolesLoading.frontEndLanguages"
                  v-model="FE_lang"
                  @blur="$v.FE_lang.$touch()"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
                <VulidateError
                  :validationField="$v.FE_lang"
                  :params="['required']"
                />
              </div>
              <div
                class="col-6 mb-7"
                :class="isFullStack ? 'col-6' : 'col-12'"
                v-if="isFullStack || isBackend"
              >
                <label class="form__label"
                  >B.E Language <span class="text-danger">*</span></label
                >
                <v-select
                  :items="backEndLanguages"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                  :disabled="
                    isRolesLoading.backEndLanguages ||
                    selectedRole === currentRole.id
                  "
                  :loading="isRolesLoading.backEndLanguages"
                  v-model="BE_lang"
                  @blur="$v.BE_lang.$touch()"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
                <VulidateError
                  :validationField="$v.BE_lang"
                  :params="['required']"
                />
              </div>
              <template v-if="isFullStack">
                <div class="col-md-6 mb-7">
                  <label class="form__label"
                    >Years of experience For Frontend
                    <span class="text-danger">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    :disabled="selectedRole === currentRole.id"
                    v-model="second_experience_years"
                    @blur="$v.second_experience_years.$touch()"
                  ></v-text-field>
                  <VulidateError
                    :validationField="$v.second_experience_years"
                    :params="[
                      'required',
                      'numeric',
                      {
                        minValue: {
                          number:
                            $v.second_experience_years.$params.minValue.min,
                        },
                      },
                      {
                        maxValue: {
                          number:
                            $v.second_experience_years.$params.maxValue.max,
                        },
                      },
                    ]"
                  />
                </div>
                <div class="col-md-6 mb-7">
                  <label class="form__label"
                    >Years of experience For Backend
                    <span class="text-danger">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="experience_years"
                    :disabled="selectedRole === currentRole.id"
                    @blur="$v.experience_years.$touch()"
                  ></v-text-field>
                  <VulidateError
                    :validationField="$v.experience_years"
                    :params="[
                      'required',
                      'numeric',
                      {
                        minValue: {
                          number: $v.experience_years.$params.minValue.min,
                        },
                      },
                      {
                        maxValue: {
                          number: $v.experience_years.$params.maxValue.max,
                        },
                      },
                    ]"
                  />
                </div>

                <div class="col-md-6 mb-7" v-if="false">
                  <label class="form__label"
                    >Months of experience For Frontend
                    <span class="text-danger">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    :disabled="selectedRole === currentRole.id"
                    v-model="second_experience_months"
                    @blur="$v.second_experience_months.$touch()"
                  ></v-text-field>
                  <VulidateError
                    :validationField="$v.second_experience_months"
                    :params="[
                      'required',
                      'numeric',
                      {
                        minValue: {
                          number:
                            $v.second_experience_months.$params.minValue.min,
                        },
                      },
                      {
                        maxValue: {
                          number:
                            $v.second_experience_months.$params.maxValue.max,
                        },
                      },
                    ]"
                  />
                </div>
                <div class="col-md-6 mb-7" v-if="false">
                  <label class="form__label"
                    >Months of experience For Backend
                    <span class="text-danger">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="experience_months"
                    :disabled="selectedRole === currentRole.id"
                    @blur="$v.experience_months.$touch()"
                  ></v-text-field>
                  <VulidateError
                    :validationField="$v.experience_months"
                    :params="[
                      'required',
                      'numeric',
                      {
                        minValue: {
                          number: $v.experience_months.$params.minValue.min,
                        },
                      },
                      {
                        maxValue: {
                          number: $v.experience_months.$params.maxValue.max,
                        },
                      },
                    ]"
                  />
                </div>
              </template>
              <template v-else>
                <div class="col-12 mb-7">
                  <label class="form__label"
                    >Years of experience
                    <span class="text-danger">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    :disabled="selectedRole === currentRole.id"
                    v-model="experience_years"
                    @blur="$v.experience_years.$touch()"
                  >
                  </v-text-field>
                  <VulidateError
                    :validationField="$v.experience_years"
                    :params="[
                      'required',
                      'numeric',
                      {
                        minValue: {
                          number: $v.experience_years.$params.minValue.min,
                        },
                      },
                      {
                        maxValue: {
                          number: $v.experience_years.$params.maxValue.max,
                        },
                      },
                    ]"
                  />
                </div>
                <div class="col-6 mb-7" v-if="false">
                  <label class="form__label"
                    >Months of experience
                    <span class="text-danger">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    :disabled="selectedRole === currentRole.id"
                    v-model="experience_months"
                    @blur="$v.experience_months.$touch()"
                  >
                  </v-text-field>
                  <VulidateError
                    :validationField="$v.experience_months"
                    :params="[
                      'required',
                      'numeric',
                      {
                        minValue: {
                          number: $v.experience_months.$params.minValue.min,
                        },
                      },
                      {
                        maxValue: {
                          number: $v.experience_months.$params.maxValue.max,
                        },
                      },
                    ]"
                  />
                </div>
              </template>
            </div>
          </div>
          <div class="col-12 mb-7">
            <div class="row">
              <div class="col-6">
                <label class="form__label"
                  >Expected Full Time Salary
                  <span class="text-danger">*</span></label
                >
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="full_time_salary"
                  @blur="$v.full_time_salary.$touch()"
                ></v-text-field>
                <VulidateError
                  :validationField="$v.full_time_salary"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <label class="form__label"
                  >Expected Part Time Salary
                  <span class="text-danger">*</span></label
                >
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="part_time_salary"
                  @blur="$v.part_time_salary.$touch()"
                ></v-text-field>
                <VulidateError
                  :validationField="$v.part_time_salary"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mb-7">
            <div class="row">
              <div class="col-6">
                <label class="form__label"
                  >Full Time Margin <span class="text-danger">*</span></label
                >
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="full_time_margin"
                  @blur="$v.full_time_margin.$touch()"
                ></v-text-field>
                <VulidateError
                  :validationField="$v.full_time_margin"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <label class="form__label"
                  >Part Time Margin <span class="text-danger">*</span></label
                >
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="part_time_margin"
                  @blur="$v.part_time_margin.$touch()"
                ></v-text-field>
                <VulidateError
                  :validationField="$v.part_time_margin"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mb-7" v-if="false">
            <div class="row">
              <div class="col-12">
                <label class="form__label">Recruiter Note</label>
                <v-textarea
                  outlined
                  dense
                  hide-details
                  v-model="recruiter_note"
                >
                </v-textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': isLoadingSubmitte,
            }"
            :disabled="isLoadingSubmitte"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import VulidateError from "@/components/shared/vulidateError.vue";
import debounce from "lodash.debounce";
import {
  required,
  requiredIf,
  numeric,
  maxValue,
  minValue,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "NominateTalentToClientModal",
  components: { ModalHeading, VulidateError },
  mixins: [validationMixin],

  props: {
    contracts: {
      type: Array,
      required: true,
    },
    activeNominate: {
      type: Object,
    },
    currentRole: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isRolesLoading: {
        roles: false,
        frontEndLanguages: false,
        backEndLanguages: false,
      },
      formMode: "add",
      isLoadingSubmitte: false,
      selectedClient: null,
      searchQuery: null,
      startDate: new Date(new Date().setDate(new Date().getDate()))
        .toISOString()
        .substr(0, 10),
      endData: new Date(new Date().setDate(new Date().getDate() + 5))
        .toISOString()
        .substr(0, 10),
      selectedRole: null,
      BE_lang: null,
      FE_lang: null,
      experience_years: null,
      second_experience_years: null,
      experience_months: null,
      second_experience_months: null,
      full_time_margin: null,
      part_time_margin: null,
      full_time_salary: null,
      part_time_salary: null,
      startDataMenu: false,
      endDataMenu: false,
      recruiter_note: null,
    };
  },
  created() {
    this.initData();
  },
  watch: {
    selectedClient(after, before) {
      if (before && !after) {
        // removed
        this.debouncedFetchItems("");
      }
    },
    activeNominate(newVal) {
      if (newVal) {
        this.formMode = "edit";
        this.setClientsList([
          {
            client_email: newVal.client_email,
            client_id: newVal.client_id,
            client_name: newVal.client_name,
            project_name: newVal.project_name,
          },
        ]);
        this.selectedClient = {
          client_email: newVal.client_email,
          client_id: newVal.client_id,
          client_name: newVal.client_name,
          project_name: newVal.project_name,
        };
        this.startDate = newVal.start_date;
        this.endData = newVal.end_date;
        this.roles = newVal.contract_type;
      } else {
        this.formMode = "add";
        this.selectedClient = null;
        this.searchQuery = null;
        this.startDate = null;
        this.endData = null;
        this.selectedRole = null;
        this.full_time_margin = null;
        this.part_time_margin = null;
        this.full_time_salary = null;
        this.part_time_salary = null;
        this.recruiter_note = null;
      }
    },
    selectedRole(newValue, oldValue) {
      if (oldValue == 3) {
        this.second_experience_years = null;
      }
      if (newValue == 3) {
        this.initData();
      }

      this.handleGetPricing();
      if (this.isFullStack) {
        this.isRolesLoading.frontEndLanguages = true;
        this.isRolesLoading.backEndLanguages = true;
        this.getFrontEndLanguages().then(
          () => (this.isRolesLoading.frontEndLanguages = false)
        );
        this.getBackEndLanguages().then(
          () => (this.isRolesLoading.backEndLanguages = false)
        );
      } else if (this.isFrontend) {
        this.isRolesLoading.frontEndLanguages = true;
        this.getFrontEndLanguages().then(
          () => (this.isRolesLoading.frontEndLanguages = false)
        );
      } else if (this.isBackend) {
        this.isRolesLoading.backEndLanguages = true;
        this.getBackEndLanguages().then(
          () => (this.isRolesLoading.backEndLanguages = false)
        );
      }
    },
    experience_years: {
      immediate: true,
      deep: true,
      handler() {
        this.handleGetPricing();
      },
    },
    second_experience_years: {
      immediate: true,
      deep: true,
      handler() {
        this.handleGetPricing();
      },
    },
    experience_months: {
      immediate: true,
      deep: true,
      handler() {
        this.handleGetPricing();
      },
    },
    second_experience_months: {
      immediate: true,
      deep: true,
      handler() {
        this.handleGetPricing();
      },
    },
    BE_lang: {
      immediate: true,
      deep: true,
      handler() {
        this.handleGetPricing();
      },
    },
    FE_lang: {
      immediate: true,
      deep: true,
      handler() {
        this.handleGetPricing();
      },
    },
  },
  validations: {
    selectedClient: { required },
    startDate: { required },
    endData: { required },
    selectedRole: { required },
    BE_lang: {
      required: requiredIf(function () {
        return this.isBackend || this.isFullStack;
      }),
    },
    FE_lang: {
      required: requiredIf(function () {
        return this.isFrontend || this.isFullStack;
      }),
    },
    full_time_margin: {
      required,
    },
    part_time_margin: {
      required,
    },
    full_time_salary: {
      required,
    },
    part_time_salary: {
      required,
    },
    experience_years: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(50),
      numeric,
    },
    second_experience_years: {
      required: requiredIf(function () {
        return this.isFullStack;
      }),
      minValue: minValue(0),
      maxValue: maxValue(50),
      numeric,
    },
    experience_months: {
      minValue: minValue(0),
      maxValue: maxValue(11),
      numeric,
    },
    second_experience_months: {
      minValue: minValue(0),
      maxValue: maxValue(11),
      numeric,
    },
  },
  computed: {
    ...mapGetters({
      clientsList: "talentProfile/getClientsList",
      roles: "talents/getRoles",
      frontEndLanguages: "talentProfile/getFrontEndLanguages",
      backEndLanguages: "talentProfile/getBackEndLanguages",
      profile: "talentProfile/getProfileData",
    }),
    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    isBackend() {
      return this.selectedRole == 1;
    },
    isFrontend() {
      return this.selectedRole == 2;
    },
    isFullStack() {
      return this.selectedRole == 3;
    },
  },
  mounted() {
    this.debouncedFetchItems = debounce(this.handleGettingClinetsList, 300);
    this.debouncedFetchItems("");
    this.getRoles().then(() => {
      this.selectedRole = this.currentRole.id;
      this.handleGetPricing();
    });
  },
  methods: {
    ...mapActions({
      getClientsList: "talentProfile/getClientsList",
      addClientToTalent: "talentProfile/addClientToTalent",
      nominateTalentToClient: "talentProfile/nominateTalentToClient",
      updateClientToTalent: "talentProfile/updateClientToTalent",
      getPricing: "talentProfile/getPricing",
      setClientsList: "talentProfile/setClientsList",
      getBasicInfo: "talentProfile/getBasicInfo",
      getRoles: "talents/getRoles",
      getFrontEndLanguages: "talentProfile/getFrontEndLanguages",
      getBackEndLanguages: "talentProfile/getBackEndLanguages",
    }),
    resetModal() {
      this.dialog = false;
      this.$v.$reset();
      this.selectedClient = null;
      this.searchQuery = null;
      this.startDate = new Date(new Date().setDate(new Date().getDate()))
        .toISOString()
        .substr(0, 10);
      this.endData = new Date(new Date().setDate(new Date().getDate() + 5))
        .toISOString()
        .substr(0, 10);
      this.selectedRole = this.currentRole.id;
      this.BE_lang = null;
      this.FE_lang = null;
      this.experience_years = null;
      this.second_experience_years = null;
      this.experience_months = null;
      this.second_experience_months = null;
      this.startDataMenu = false;
      this.endDataMenu = false;
      this.full_time_margin = null;
      this.part_time_margin = null;
      this.full_time_salary = null;
      this.part_time_salary = null;
      this.recruiter_note = null;
      this.initData();
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.handleNominateTalentToClient();
      }
    },
    initData() {
      if (this.$route.params.id) {
        this.profileId = this.$route.params.id;
        this.getBasicInfo(this.profileId).then((res) => {
          this.BE_lang = res.BE_lang?.id || null;
          this.FE_lang = res.FE_lang?.id || null;
          this.experience_years = res.experience_years;
          this.second_experience_years = res.second_experience_years;
          this.experience_months = res.experience_months;
          this.second_experience_months = res.second_experience_months;
          this.handleGetPricing();
        });
      }
    },
    handleGetPricing() {
      if (this.selectedRole) {
        let prisingPayload = {
          full_time_salary: this.profile.basicInfo.salary,
          part_time_salary: this.profile.basicInfo.part_time_salary,
          role_id: this.selectedRole,
          experience_years: Math.max(
            this.experience_years,
            this.second_experience_years
          ),
          experience_months: Math.max(
            this.experience_months,
            this.second_experience_months
          ),
          backend_language_id: this.BE_lang,
          frontend_language_id: this.FE_lang,
        };

        if (this.selectedRole === 1 && this.BE_lang != null) {
          this.getPricing(prisingPayload)
            .then((res) => {
              this.full_time_margin = res.full_time.margin;
              this.part_time_margin = res.part_time.margin;
              this.full_time_salary = res.full_time.salary;
              this.part_time_salary = res.part_time.salary;
            })
            .catch((err) => {
              console.error("err :>> ", err);
            });

          // Code to execute if this.selectedRole is 1 and this.BE_lang exists
        } else if (
          this.selectedRole === 2 &&
          this.FE_lang &&
          this.FE_lang != null
        ) {
          this.getPricing(prisingPayload)
            .then((res) => {
              this.full_time_margin = res.full_time.margin;
              this.part_time_margin = res.part_time.margin;
              this.full_time_salary = res.full_time.salary;
              this.part_time_salary = res.part_time.salary;
            })
            .catch((err) => {
              console.error("err :>> ", err);
            });

          // Code to execute if this.selectedRole is 2 and this.FE_lang exists
        } else if (this.selectedRole === 3 && this.BE_lang && this.FE_lang) {
          // Code to execute if this.selectedRole is 3 and both this.BE_lang and this.FE_lang exist
          this.getPricing(prisingPayload)
            .then((res) => {
              this.full_time_margin = res.full_time.margin;
              this.part_time_margin = res.part_time.margin;
              this.full_time_salary = res.full_time.salary;
              this.part_time_salary = res.part_time.salary;
            })
            .catch((err) => {
              console.error("err :>> ", err);
            });
        } else if (
          this.selectedRole !== 1 &&
          this.selectedRole !== 2 &&
          this.selectedRole !== 3
        ) {
          this.getPricing(prisingPayload)
            .then((res) => {
              this.full_time_margin = res.full_time.margin;
              this.part_time_margin = res.part_time.margin;
              this.full_time_salary = res.full_time.salary;
              this.part_time_salary = res.part_time.salary;
            })
            .catch((err) => {
              console.error("err :>> ", err);
            });
        }
      }
    },
    handleNominateTalentToClient() {
      this.isLoadingSubmitte = true;
      if (this.formMode == "add") {
        const data = {
          client_project_id: this.selectedClient.project_id,
          role_id: this.selectedRole,
          start_date: this.startDate,
          end_date: this.endData,
          experience_years: Math.max(
            this.experience_years,
            this.second_experience_years
          ),
          experience_months: Math.max(
            this.experience_months,
            this.second_experience_months
          ),
          backend_language_id: this.BE_lang,
          frontend_language_id: this.FE_lang,
          full_time_margin: this.full_time_margin,
          part_time_margin: this.part_time_margin,
          full_time_salary: this.full_time_salary,
          part_time_salary: this.part_time_salary,
          recruiter_note: this.recruiter_note,
        };

        let payload = {
          client: data,
          talentId: this.$route.params.id,
        };
        this.nominateTalentToClient(payload)
          .then(() => {
            this.isLoadingSubmitte = false;
            this.dialog = false;
            this.resetModal();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingSubmitte = false;
          });
      } else {
        const data = {
          client_project_id: this.selectedClient.project_id,
          role_id: this.selectedRole,
          start_date: this.startDate,
          end_date: this.endData,
        };

        let payload = {
          contractId: this.activeClient.id,
          talentId: this.$route.params.id,
          data,
        };

        this.updateClientToTalent(payload)
          .then(() => {
            this.isLoadingSubmitte = false;
            this.dialog = false;
            this.resetModal();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingSubmitte = false;
          });
      }
    },
    handleGettingClinetsList(payload) {
      this.isLoading = true;
      this.getClientsList(payload)
        .then(() => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.v-select-input__contract-type input {
  cursor: pointer;
}
</style>

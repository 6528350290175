<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
    @input="initLanguagesData"
  >
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <ModalHeading modalTitle="Edit Language" />
      <form class="form pt-3" @submit.prevent="submitForm">
        <label class="form__label"
          >What is your First Language?
          <span class="text-danger">*</span></label
        >
        <div class="row">
          <div class="col-md-6 mb-7">
            <v-select
              :items="lANGUAGES"
              outlined
              dense
              hide-details
              placeholder="Select Language"
              v-model="languagesObj.firstLang"
              @blur="$v.languagesObj.firstLang.$touch()"
            ></v-select>
            <VulidateError
              :validationField="$v.languagesObj.firstLang"
              :params="['required']"
            />
          </div>
          <div class="col-md-6 mb-7">
            <v-select
              :items="LEVELS"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              placeholder="Select a Level"
              v-model="languagesObj.firstLangLevel"
              @blur="$v.languagesObj.firstLangLevel.$touch()"
            ></v-select>
            <VulidateError
              :validationField="$v.languagesObj.firstLangLevel"
              :params="['required']"
            />
          </div>
        </div>
        <label class="form__label">What is your Second Language? </label>
        <div class="row">
          <div class="col-md-6 mb-7">
            <v-select
              :items="
                languagesObj.firstLang
                  ? lANGUAGES.filter((lang) => lang !== languagesObj.firstLang)
                  : lANGUAGES
              "
              outlined
              dense
              hide-details
              placeholder="Select Language"
              v-model="languagesObj.secondLang"
              clearable
            ></v-select>
            <VulidateError
              :validationField="$v.languagesObj.secondLang"
              :params="['required']"
            />
          </div>
          <div class="col-md-6 mb-7">
            <v-select
              :items="LEVELS"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              placeholder="Select a Level"
              v-model="languagesObj.secondLangLevel"
              @blur="$v.languagesObj.secondLangLevel.$touch()"
              clearable
            ></v-select>
            <VulidateError
              :validationField="$v.languagesObj.secondLangLevel"
              :params="['required']"
            />
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': loading,
            }"
            :disabled="loading"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import VulidateError from "@/components/shared/vulidateError.vue";
import { lANGUAGES, LEVELS } from "@/constants/languages";

const initLanguagesObj = {
  firstLang: "",
  firstLangLevel: "",
  secondLang: "",
  secondLangLevel: "",
};
export default {
  name: "LanguagesFormModal",
  mixins: [validationMixin],
  components: {
    ModalHeading,
    VulidateError,
  },
  props: {
    profileLanguages: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      languagesObj: { ...initLanguagesObj },
      lANGUAGES,
      LEVELS,
    };
  },
  validations: {
    languagesObj: {
      firstLang: {
        required,
      },
      firstLangLevel: {
        required,
      },
      secondLang: {
        required: requiredIf(function () {
          return this.languagesObj.secondLangLevel;
        }),
      },
      secondLangLevel: {
        required: requiredIf(function () {
          return this.languagesObj.secondLang;
        }),
      },
    },
  },

  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
    }),
  },
  methods: {
    resetModal() {
      this.dialog = false;
      this.$v.$reset();
      this.languagesObj = { ...initLanguagesObj };
    },
    initLanguagesData() {
      if (this.profileLanguages[0]) {
        this.languagesObj.firstLang = this.profileLanguages[0].name;
        this.languagesObj.firstLangLevel = this.profileLanguages[0].level;
      }
      if (this.profileLanguages[1]) {
        this.languagesObj.secondLang = this.profileLanguages[1].name;
        this.languagesObj.secondLangLevel = this.profileLanguages[1].level;
      }
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let { firstLang, firstLangLevel, secondLang, secondLangLevel } =
        this.languagesObj;
      let languages = [{ name: firstLang, level: firstLangLevel }];
      if (secondLang) {
        languages.push({ name: secondLang, level: secondLangLevel });
      }

      let payload = {
        data: { languages },
        talent_id: this.$route.params.id,
      };
      this.$store
        .dispatch("talentProfile/editTalentLanguages", payload)
        .then(() => {
          this.resetModal();
        });
    },
  },
};
</script>

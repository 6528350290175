<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              v-for="(step, index) in wizardSteps"
              :key="index"
            >
              <div class="wizard-wrapper p-4">
                <div class="wizard-label text-center w-100">
                  <v-badge
                    bordered
                    color="red"
                    icon="fa-solid fa-exclamation"
                    v-if="
                      (step.name === 'Experience' &&
                        experienceHasAnEmptyRow.length) ||
                      (step.name === 'Education' &&
                        educationHasAnEmptyRow.length)
                    "
                  >
                    <i
                      class="wizard-icon display-2"
                      :class="`${step.icon}`"
                    ></i>
                    <div class="wizard-title">{{ step.name }}</div>
                  </v-badge>
                  <template v-else>
                    <i
                      class="wizard-icon display-2"
                      :class="`${step.icon}`"
                    ></i>
                    <div class="wizard-title">{{ step.name }}</div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-8">
            <div class="row justify-content-center">
              <div class="col">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <SkillsAndProjects :profile="profile" />
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <Experience
                      :profile="profile"
                      :experienceHasAnEmptyRow="experienceHasAnEmptyRow"
                    />
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <Education
                      :profile="profile"
                      :educationHasAnEmptyRow="educationHasAnEmptyRow"
                    />
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <Certificates :profile="profile" />
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Step 5-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <Languages :profile="profile" />
                  </div>
                  <!--end: Wizard Step 5-->

                  <!--begin: Wizard Step 6-->
                  <!--  <div class="pb-5" data-wizard-type="step-content">
                    <Clients :profile="profile" />
                  </div> -->
                  <!--end: Wizard Step 6-->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import KTWizard from "@/assets/js/components/wizard";
import SkillsAndProjects from "@/components/talent-profile/wizard-tabs/SkillsAndProjects.vue";
import Experience from "@/components/talent-profile/wizard-tabs/Experience.vue";
import Education from "@/components/talent-profile/wizard-tabs/Education.vue";
import Certificates from "@/components/talent-profile/wizard-tabs/Certificates.vue";
import Languages from "@/components/talent-profile/wizard-tabs/Languages.vue";
/* import Clients from "@/components/talent-profile/wizard-tabs/Clients.vue";
 */
export default {
  name: "TalentWizard",
  props: {
    profile: {
      required: true,
    },
  },
  components: {
    SkillsAndProjects,
    Experience,
    Education,
    Certificates,
    Languages,
  },
  mounted() {
    // Initialize form wizard
    new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });
  },
  computed: {
    educationHasAnEmptyRow() {
      return this.profile.educations.filter((education) => {
        let educationCheck = { ...education };

        if (educationCheck.currently) {
          delete educationCheck.end_month;
          delete educationCheck.end_year;
        }

        if (educationCheck.parsing_id == null) delete educationCheck.parsing_id;

        delete educationCheck.degree;

        return this.checkProperties(educationCheck);
      });
    },
    experienceHasAnEmptyRow() {
      return this.profile.experiences.filter((experience) => {
        let experienceCheck = { ...experience };

        if (experienceCheck.currently_work_in) {
          delete experienceCheck.end_month;
          delete experienceCheck.end_year;
        }

        if (experienceCheck.parsing_id == null)
          delete experienceCheck.parsing_id;

        return this.checkProperties(experienceCheck);
      });
    },
  },
  methods: {
    checkProperties(obj) {
      const isEmpty = !Object.values(obj).every((value) => value !== null);
      return isEmpty;
    },
  },
  data() {
    return {
      wizardSteps: [
        {
          name: "Skills & projects",
          icon: "flaticon-list",
        },
        {
          name: "Experience",
          icon: "flaticon-statistics",
        },
        {
          name: "Education",
          icon: "flaticon2-open-text-book",
        },
        {
          name: "Certificates",
          icon: "flaticon-medal",
        },
        {
          name: "Languages",
          icon: "flaticon2-world",
        },
        /* {
          name: "Clients",
          icon: "flaticon-users"
        } */
      ],
    };
  },
};
</script>

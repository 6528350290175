var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},on:{"input":_vm.initData},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_vm._t("default",null,{"on":on})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"p-5 p-md-8"},[_c('div',{staticClass:"modal__heading"},[_c('h2',{staticClass:"h1 text-info d-flex align-items-center justify-content-between"},[_vm._v(" Select Skills "),_c('b-button',{staticClass:"px-2 ml-3",attrs:{"size":"sm","disabled":!_vm.skillsListComputed.filter((skill) => !skill.disable).length,"variant":"outline-info"},on:{"click":function($event){return _vm.selectedSkills.push({
              id: null,
              months: 0,
              name: null,
              years: 0,
              justAdded: true,
            })}}},[_c('i',{staticClass:"fas fa-plus-circle px-0"})])],1),_c('hr')]),(!_vm.selectedSkills.length)?_c('h6',{staticClass:"text-muted mb-0"},[_vm._v("No Data")]):_vm._e(),_c('form',{staticClass:"form pt-3",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._l((_vm.selectedSkills),function(skill,i){return _c('div',{key:i,staticClass:"form-group row selectedSkills-row"},[_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"skills-list"},[_c('v-autocomplete',{ref:"skills-list",refInFor:true,attrs:{"items":_vm.skillsListComputed,"item-disabled":"disable","item-text":"name","item-value":"id","outlined":"","hide-details":"","chips":"","dense":"","small-chips":"","label":"Skills","menu-props":{},"search-input":_vm.skillsSearch},on:{"update:searchInput":function($event){_vm.skillsSearch=$event},"update:search-input":function($event){_vm.skillsSearch=$event},"change":function($event){_vm.skillsSearch = ''},"keydown":function($event){(skill.id = null),
                  (skill.justAdded = true),
                  (skill.name = null)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Skills "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true},{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(data.item.name),expression:"data.item.name",modifiers:{"hover":true}}],attrs:{"input-value":data.selected,"color":"white","text-color":"black"},domProps:{"textContent":_vm._s(
                    `${
                      data.item.name.length >= 18
                        ? data.item.name.substring(0, 18) + '...'
                        : data.item.name
                    }`
                  )}},'v-chip',data.attrs,false))]}},{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(item.name),expression:"item.name",modifiers:{"hover":true}}],domProps:{"textContent":_vm._s(
                      `${
                        item.name.length >= 18
                          ? item.name.substring(0, 18) + '...'
                          : item.name
                      }`
                    )}})],1)]}}],null,true),model:{value:(skill.id),callback:function ($$v) {_vm.$set(skill, "id", $$v)},expression:"skill.id"}}),(skill.id == null && _vm.isSubmitted)?_c('div',{staticClass:"form__error-label"},[_vm._v(" Please select the skill ")]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{},[_c('v-select',{attrs:{"items":Array.from({ length: 50 }, (_, i) => i + 1),"outlined":"","dense":"","hide-details":"","label":"Years","clearable":""},model:{value:(skill.years),callback:function ($$v) {_vm.$set(skill, "years", $$v)},expression:"skill.years"}})],1)]),_c('div',{staticClass:"col-3"},[_c('div',{},[_c('v-select',{attrs:{"items":Array.from({ length: 11 }, (_, i) => i + 1),"outlined":"","dense":"","hide-details":"","label":"Months","clearable":""},model:{value:(skill.months),callback:function ($$v) {_vm.$set(skill, "months", $$v)},expression:"skill.months"}})],1)]),_c('div',{staticClass:"col-1"},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"btn btn-secondary font-weight-bolder font-size-h6 px-2 ml-3",staticStyle:{"height":"45px","width":"50px"},attrs:{"variant":"outline"},on:{"click":function($event){return _vm.deleteSkills(skill.id, i)}}},[_c('i',{staticClass:"fas fa-plus-circle px-0",staticStyle:{"transform":"rotate(45deg)"}})])],1)])])}),_c('div',{staticClass:"text-right mt-5"},[_c('button',{staticClass:"btn btn-secondary font-weight-bolder font-size-h6 px-8",attrs:{"type":"button"},on:{"click":_vm.resetModal}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-info font-weight-bolder font-size-h6 px-8 ml-5",class:{
            'spinner spinner-light spinner-right': _vm.loading,
          },attrs:{"disabled":_vm.loading || !_vm.selectedSkills.length}},[_vm._v(" Save ")])])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <div class="d-flex">
        <ModalHeading
          :modalTitle="'Clinets Inquiry About The Talent'"
          class="w-100"
        />
        <v-btn icon dark @click="dialog = false">
          <i
            class="flaticon-cancel text-dark font-weight-bold my-4 cursor-pointer"
            style="font-size: 2rem"
          >
          </i>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalHeading from "@/components/UI/ModalHeading.vue";

export default {
  components: {
    ModalHeading,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
    @input="handleLoadingProjectTypes"
  >
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <ModalHeading
        :modalTitle="formMode === 'add' ? 'Add Project' : 'Edit Project'"
        modalDesc="Enter Project Details"
      />
      <form class="form pt-3" @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >Project Name <span class="text-danger">*</span></label
            >
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Project Name"
              v-model="project.name"
              @blur="$v.project.name.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.project.name"
              :params="[
                'required',
                {
                  maxLength: {
                    number: $v.project.name.$params.maxLength.max,
                  },
                },
              ]"
            />
          </div>
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >Project Types <span class="text-danger">*</span></label
            >
            <v-select
              :items="projectTypes"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              placeholder="Project Type"
              :loading="isLoading.projectTypes"
              :disabled="isLoading.projectTypes"
              v-model="project.project_type_id"
              @blur="$v.project.project_type_id.$touch()"
            ></v-select>
            <VulidateError
              :validationField="$v.project.project_type_id"
              :params="['required']"
            />
          </div>
          <div class="col-12 mb-7">
            <label class="form__label">Project Link</label>
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Project Link"
              v-model="project.link"
              @blur="$v.project.link.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.project.link"
              :params="[
                'urlFormat',
                {
                  maxLength: {
                    number: $v.project.link.$params.maxLength.max,
                  },
                },
              ]"
            />
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': isLoading.savingData,
            }"
            :disabled="isLoading.savingData"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
//import { required, maxLength, helpers } from "vuelidate/lib/validators";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import VulidateError from "@/components/shared/vulidateError.vue";
import { urlFormat } from "@/helpers";
//const alphaNumSpace = helpers.regex("alphaNumSpace", /^[A-Za-z0-9 ]+$/);

const initProjectObj = {
  name: "",
  project_type_id: null,
  link: "",
};
export default {
  name: "ProjectsFormModal",
  mixins: [validationMixin],
  components: {
    ModalHeading,
    VulidateError,
  },
  props: {
    activeProject: {
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      formMode: "add",
      isLoading: {
        projectTypes: false,
        savingData: false,
      },
      project: { ...initProjectObj },
    };
  },
  watch: {
    activeProject(newVal) {
      if (newVal) {
        this.formMode = "edit";
        this.project = { ...newVal };
      } else {
        this.formMode = "add";
        this.project = { ...initProjectObj };
      }
    },
  },
  validations: {
    project: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      project_type_id: {
        required,
      },
      link: {
        urlFormat,
        maxLength: maxLength(2000),
      },
    },
  },
  computed: {
    ...mapGetters({
      projectTypes: "shared/getProjectTypes",
    }),
  },
  methods: {
    ...mapActions({
      getProjectTypes: "shared/getProjectTypes",
      addTalentProject: "talentProfile/addTalentProject",
      editTalentProject: "talentProfile/editTalentProject",
    }),
    handleLoadingProjectTypes() {
      this.isLoading.projectTypes = true;
      this.getProjectTypes().then(() => {
        this.isLoading.projectTypes = false;
      });
    },
    resetModal() {
      this.dialog = false;
      this.$v.$reset();
      this.project = { ...initProjectObj };
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let payload = {
        data: this.project,
        talent_id: this.$route.params.id,
      };
      this.isLoading.savingData = true;
      if (this.formMode === "add") {
        this.addTalentProject(payload)
          .then(() => {
            this.resetModal();
          })
          .finally(() => {
            this.isLoading.savingData = false;
          });
      } else {
        this.editTalentProject(payload)
          .then(() => {
            this.resetModal();
          })
          .finally(() => {
            this.isLoading.savingData = false;
          });
      }
    },
  },
};
</script>

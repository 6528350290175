var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom gutter-b form"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"font-weight-bolder text-dark mb-2 d-flex align-items-center justify-content-between"},[_vm._v(" Bio "),(!_vm.editForm)?_c('b-button',{staticClass:"px-2 ml-3",attrs:{"size":"sm","variant":"outline-info"},on:{"click":_vm.showEditForm}},[_c('i',{staticClass:"fas fa-pen px-0"})]):_vm._e()],1),(_vm.editForm)?[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.bio),expression:"bio"}],ref:"talent-bio",staticClass:"form-control form__input",class:{
          'form__input--error': _vm.$v.bio.$dirty && _vm.$v.bio.$error,
        },attrs:{"placeholder":"Enter Talent Bio","rows":"4"},domProps:{"value":(_vm.bio)},on:{"blur":function($event){return _vm.$v.bio.$touch()},"input":function($event){if($event.target.composing)return;_vm.bio=$event.target.value}}}),_c('VulidateError',{attrs:{"validationField":_vm.$v.bio,"params":[
          {
            maxLength: {
              number: _vm.$v.bio.$params.maxLength.max,
            },
          },
        ]}}),_c('div',{staticClass:"d-flex mt-4"},[_c('button',{staticClass:"btn btn-info font-weight-bolder px-5",class:{
            'spinner spinner-light spinner-right': _vm.isLoading,
          },attrs:{"disabled":_vm.isLoading},on:{"click":_vm.saveBio}},[_c('span',{staticClass:"px-4"},[_vm._v("Save")])]),_c('button',{staticClass:"btn btn-secondary font-weight-bolder px-5 ml-5",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.resetEditForm}},[_vm._v(" Cancel ")])])]:[(_vm.profile.basicInfo.bio)?_c('pre',{staticClass:"mb-0 font-size-lg"},[_vm._v(_vm._s(_vm.profile.basicInfo.bio.trim()))]):_c('h6',{staticClass:"text-muted mb-0"},[_vm._v("No Data")])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"font-weight-bolder text-dark mb-4 d-flex align-items-center justify-content-between"},[_vm._v(" Education "),_c('b-button',{staticClass:"px-2 ml-3",attrs:{"size":"sm","variant":"outline-info","disabled":_vm.profile.educations.length >= 25},on:{"click":function($event){return _vm.showEducationFormModal(null)}}},[_c('i',{staticClass:"fas fa-plus-circle px-0"})])],1),(_vm.profile.educations.length)?_c('div',{staticClass:"wizard-timeline"},_vm._l((_vm.profile.educations),function(education){return _c('div',{key:education.id,staticClass:"wizard-timeline__item"},[_c('div',{staticClass:"card py-4 px-5 shadow-xs flex-grow-1",class:{
          'wizard-timeline__border': _vm.educationHasAnEmptyRow.find(
            (e) => e.id === education.id
          ),
        }},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('div',{staticClass:"grey--text mb-1"},[_vm._v(" ("),(education.start_month !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.getMonthName(education.start_month))+" "+_vm._s(education.start_year)+" - ")]):_c('span',[_vm._v(" - - ")]),(education.currently == 1)?_c('span',[_vm._v("Present")]):_vm._e(),(
                  education.currently == 0 && education.end_month !== null
                )?_c('span',[_vm._v(_vm._s(_vm.getMonthName(education.end_month))+" "+_vm._s(education.end_year))]):(education.currently == 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(" ) ")]),_c('h4',{staticClass:"mb-2"},[(education.university)?_c('span',[_vm._v(" "+_vm._s(education.university)+" ")]):_vm._e(),(education.degree)?_c('span',{staticClass:"wizard-timeline__item__highlighted-text font-size-lg grey--text text--darken-1"},[_vm._v(" "+_vm._s(education.degree)+" ")]):_vm._e()])]),_c('div',{staticClass:"ml-4 d-flex align-items-center"},[(_vm.educationHasAnEmptyRow.find((e) => e.id === education.id))?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Required "),_c('v-avatar',{attrs:{"right":""}},[_c('v-icon',[_vm._v("report_problem")])],1)],1):_vm._e(),_c('b-dropdown',{attrs:{"variant":"outline-info","right":"","no-caret":"","toggle-class":"p-2 ml-3","menu-class":"p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"flaticon-more px-0"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showEducationFormModal(education)}}},[_vm._v("Edit")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteProject(education.id)}}},[_vm._v("Delete")])],1)],1)]),(education.study_field)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(education.study_field)+" ")]):_vm._e()])])}),0):_c('h6',{staticClass:"text-muted mb-0"},[_vm._v("No Data")]),_c('EducationFormModal',{attrs:{"activeEducation":_vm.activeEducation,"educationHasAnEmptyRow":_vm.educationHasAnEmptyRow},scopedSlots:_vm._u([{key:"default",fn:function({ on }){return [_c('span',_vm._g({ref:"education-modal"},on))]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
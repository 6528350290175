<template>
  <div>
    <h4
      class="font-weight-bolder text-dark mb-4 d-flex align-items-center justify-content-between"
    >
      Education
      <b-button
        size="sm"
        variant="outline-info"
        class="px-2 ml-3"
        @click="showEducationFormModal(null)"
        :disabled="profile.educations.length >= 25"
        ><i class="fas fa-plus-circle px-0"></i
      ></b-button>
    </h4>

    <div class="wizard-timeline" v-if="profile.educations.length">
      <div
        class="wizard-timeline__item"
        v-for="education in profile.educations"
        :key="education.id"
      >
        <div
          class="card py-4 px-5 shadow-xs flex-grow-1"
          :class="{
            'wizard-timeline__border': educationHasAnEmptyRow.find(
              (e) => e.id === education.id
            ),
          }"
        >
          <div class="d-flex justify-content-between">
            <div>
              <div class="grey--text mb-1">
                (<span v-if="education.start_month !== null">
                  {{ getMonthName(education.start_month) }}
                  {{ education.start_year }} -
                </span>
                <span v-else> - - </span>
                <span v-if="education.currently == 1">Present</span>
                <span
                  v-if="
                    education.currently == 0 && education.end_month !== null
                  "
                  >{{ getMonthName(education.end_month) }}
                  {{ education.end_year }}</span
                >
                <span v-else-if="education.currently == 0">-</span>
                )
              </div>
              <h4 class="mb-2">
                <span v-if="education.university">
                  {{ education.university }}
                </span>
                <span
                  class="wizard-timeline__item__highlighted-text font-size-lg grey--text text--darken-1"
                  v-if="education.degree"
                >
                  {{ education.degree }}
                </span>
              </h4>
            </div>
            <div class="ml-4 d-flex align-items-center">
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                v-if="educationHasAnEmptyRow.find((e) => e.id === education.id)"
              >
                Required
                <v-avatar right>
                  <v-icon>report_problem</v-icon>
                </v-avatar>
              </v-chip>
              <b-dropdown
                variant="outline-info"
                right
                no-caret
                toggle-class="p-2 ml-3"
                menu-class="p-0"
              >
                <template #button-content>
                  <i class="flaticon-more px-0"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  @click.prevent="showEducationFormModal(education)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click.prevent="confirmDeleteProject(education.id)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
          <p class="mb-0" v-if="education.study_field">
            {{ education.study_field }}
          </p>
        </div>
      </div>
    </div>

    <h6 class="text-muted mb-0" v-else>No Data</h6>

    <EducationFormModal
      :activeEducation="activeEducation"
      :educationHasAnEmptyRow="educationHasAnEmptyRow"
    >
      <template #default="{ on }">
        <span ref="education-modal" v-on="on"></span>
      </template>
    </EducationFormModal>
  </div>
</template>

<script>
import EducationFormModal from "@/components/talent-profile/modals/EducationFormModal.vue";
import Swal from "sweetalert2";
import MONTHS from "@/constants/months";
export default {
  name: "Education",
  components: { EducationFormModal },
  props: {
    profile: {
      required: true,
    },
    educationHasAnEmptyRow: {
      type: Array,
    },
  },
  data() {
    return {
      activeEducation: null,
    };
  },
  methods: {
    getMonthName(monthId) {
      return MONTHS.find((month) => month.id === monthId)?.name;
    },
    showEducationFormModal(education) {
      if (education) {
        this.activeEducation = JSON.parse(JSON.stringify(education));
      } else {
        this.activeEducation = null;
      }
      this.$refs["education-modal"].click();
    },
    confirmDeleteProject(educationId) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            educationId,
            talent_id: this.$route.params.id,
          };
          this.$store.dispatch("talentProfile/deleteTalentEducation", payload);
        }
      });
    },
  },
};
</script>

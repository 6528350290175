<template>
  <div>
    <h4
      class="font-weight-bolder text-dark mb-4 d-flex align-items-center justify-content-between"
    >
      Skills
      <b-button
        size="sm"
        variant="outline-info"
        class="px-2 ml-3"
        :disabled="!profile.basicInfo.role"
        @click="showSkillsFormModal"
      >
        <i
          class="fas fa-plus-circle px-0"
          v-if="!profile.basicInfo.skills.length"
        >
        </i>
        <i class="fas fa-pen px-0" v-else> </i>
      </b-button>
    </h4>

    <template v-if="profile.basicInfo.skills.length">
      <div class="talent-skills">
        <ul>
          <li v-for="skill in profile.basicInfo.skills" :key="skill.id">
            {{ skill.name }}
            <span v-if="skill.years > 0 || skill.months > 0">
              {{ skill.years > 0 ? skill.years + " Y" : "" }}
              <span
                style="padding: 0"
                v-if="skill.years > 0 && skill.months > 0"
              >
                &
              </span>
              {{ skill.months > 0 ? skill.months + " M" : "" }}

              of experience
            </span>
          </li>
        </ul>
      </div>

      <!--       <v-chip
        class="mr-2 mb-2"
        label
        v-for="skill in profile.basicInfo.skills"
        :key="skill.id"
        color="primary"
        outlined
      >
        {{ skill.name }}
      </v-chip> -->
    </template>
    <h6 class="text-muted mb-0" v-else>No Data</h6>

    <h4
      class="font-weight-bolder text-dark mb-4 pt-10 d-flex align-items-center justify-content-between"
    >
      Projects
      <b-button
        size="sm"
        variant="outline-info"
        class="px-2 ml-3"
        @click="showProjectsFormModal(null)"
        :disabled="projectsNumber >= 25"
        ><i class="fas fa-plus-circle px-0"></i
      ></b-button>
    </h4>

    <template v-if="Object.keys(profile.projects).length">
      <div
        class="card pt-5 px-5 pb-1 shadow-sm mt-4"
        v-for="category in Object.keys(profile.projects)"
        :key="category"
      >
        <h4 class="mb-5">{{ category }}</h4>
        <ul class="mb-0 p-0 projects-list">
          <li
            class="d-flex align-items-baseline font-size-lg py-4 projects-list__item"
            v-for="project in profile.projects[category]"
            :key="project.id"
          >
            <span
              class="projects-list__item__name d-flex align-items-baseline mr-3"
              >{{ project.name }}</span
            >
            <div class="ml-auto d-flex align-items-center">
              <a
                v-if="project.link"
                :href="getUrl(project.link)"
                class="text-info projects-list__item__link text-nowrap"
                target="_blank"
                >View Project</a
              >
              <b-dropdown
                variant="outline-info"
                right
                no-caret
                toggle-class="p-2 ml-3"
                menu-class="p-0"
              >
                <template #button-content>
                  <i class="flaticon-more px-0"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  @click.prevent="showProjectsFormModal(project)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click.prevent="confirmDeleteProject(project.id)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </li>
        </ul>
      </div>
    </template>

    <h6 class="text-muted mb-0" v-else>No Data</h6>

    <ProjectFormModal :activeProject="activeProject">
      <template #default="{ on }">
        <span ref="projects-modal" v-on="on"></span>
      </template>
    </ProjectFormModal>

    <SkillsFormModal
      :talentRole="profile.basicInfo.role"
      :talentSkills="JSON.parse(JSON.stringify([...profile.basicInfo.skills]))"
    >
      <template #default="{ on }">
        <span ref="skills-modal" v-on="on"></span>
      </template>
    </SkillsFormModal>
  </div>
</template>

<script>
import ProjectFormModal from "@/components/talent-profile/modals/ProjectFormModal.vue";
import SkillsFormModal from "@/components/talent-profile/modals/SkillsFormModal.vue";
import Swal from "sweetalert2";
import { getUrl } from "@/helpers";
export default {
  name: "SkillsAndProjects",
  components: { ProjectFormModal, SkillsFormModal },
  props: {
    profile: {
      required: true,
    },
  },
  data() {
    return {
      activeProject: null,
    };
  },
  computed: {
    projectsNumber() {
      return (
        (Object.values(this.profile.projects).length &&
          Object.values(this.profile.projects).reduce(
            (prev, current) => prev + current.length,
            0
          )) ||
        0
      );
    },
  },
  methods: {
    getUrl,
    showProjectsFormModal(project) {
      if (project) {
        this.activeProject = JSON.parse(JSON.stringify(project));
        this.activeProject.project_type_id = project.project_type?.id;
        delete this.activeProject.project_type;
      } else {
        this.activeProject = null;
      }
      this.$refs["projects-modal"].click();
    },
    showSkillsFormModal() {
      this.$refs["skills-modal"].click();
    },
    confirmDeleteProject(projectId) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            projectId,
            talent_id: this.$route.params.id,
          };
          this.$store.dispatch("talentProfile/deleteProjectRequest", payload);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.projects-list {
  &__item {
    &:not(:last-of-type) {
      border-bottom: 2px solid lighten($color: #6f56c5, $amount: 35%);
    }
    &__name {
      &::before {
        content: "";
        display: inline-block;
        min-width: 8px;
        height: 8px;
        background: lighten($color: #6f56c5, $amount: 35%);
        margin-right: 0.5rem;
      }
    }
    &__link {
      text-decoration: underline;
    }
    ::v-deep .dropdown-item:active {
      background: $purpleColor;
    }
  }
}

.talent-skills {
  overflow: hidden;

  ul {
    justify-content: flex-start;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  span {
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 500;
  }

  li {
    background-color: #fff;
    border: 1px solid rgba(238, 238, 238, 1);
    border-radius: 30px;
    padding: 10px 15px;
    list-style: none;
    display: inline-block;
    margin: 2px;
    font-size: 15px;
    color: rgba(73, 77, 85, 1);
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>

<template>
  <div>
    <h4
      class="font-weight-bolder text-dark mb-4 d-flex align-items-center justify-content-between"
    >
      Languages
      <b-button
        size="sm"
        variant="outline-info"
        class="px-2 ml-3"
        @click="showLanguagesFormModal"
        ><i class="fas fa-pen px-0"></i
      ></b-button>
    </h4>
    <div
      class="card py-1 px-5 shadow-sm mt-4"
      v-if="profile.basicInfo.languages.length"
    >
      <ul class="mb-0 p-0 languages-list">
        <li
          class="d-flex align-items-baseline font-size-lg py-4 languages-list__item"
          v-for="(language, index) in profile.basicInfo.languages"
          :key="index"
        >
          <span
            class="languages-list__item__name d-flex align-items-baseline mr-3"
            >{{ language.name }}</span
          >
          <div class="ml-auto d-flex align-items-center">
            {{ getLanguageLevel(language.level) }}
          </div>
        </li>
      </ul>
    </div>

    <h6 class="text-muted mb-0" v-else>No Data</h6>

    <LanguagesFormModal :profileLanguages="profile.basicInfo.languages">
      <template #default="{ on }">
        <span ref="languages-modal" v-on="on"></span>
      </template>
    </LanguagesFormModal>
  </div>
</template>

<script>
import LanguagesFormModal from "@/components/talent-profile/modals/LanguagesFormModal.vue";
import { LEVELS } from "@/constants/languages";
export default {
  name: "Languages",
  components: { LanguagesFormModal },
  props: {
    profile: {
      required: true,
    },
  },
  methods: {
    getLanguageLevel(levelId) {
      return LEVELS.find((level) => level.id === levelId).name;
    },
    showLanguagesFormModal() {
      this.$refs["languages-modal"].click();
    },
  },
};
</script>
<style lang="scss" scoped>
.languages-list {
  &__item {
    &:not(:last-of-type) {
      border-bottom: 2px solid lighten($color: #6f56c5, $amount: 35%);
    }
    &__name {
      &::before {
        content: "";
        display: inline-block;
        min-width: 8px;
        height: 8px;
        background: lighten($color: #6f56c5, $amount: 35%);
        margin-right: 0.5rem;
      }
    }
  }
}
</style>

<template>
  <v-app v-if="profile && profile.basicInfo">
    <BasicInfoCard :profile="profile" :profileId="profileId" />
    <TalentBio :profile="profile" :profileId="profileId" />
    <SalaryAndAvailability :profile="profile" :profileId="profileId" />
    <TalentWizard :profile="profile" :profileId="profileId" />

    <div class="history-fixed-button">
      <v-btn
        color="primary"
        large
        class="mx-4"
        @click.stop="contractsTalantsHistory = !contractsTalantsHistory"
      >
        History
      </v-btn>
    </div>

    <v-navigation-drawer
      v-model="contractsTalantsHistory"
      right
      fixed
      temporary
      width="auto"
      class="contracts-history"
    >
      <v-list-item class="d-flex justify-content-between">
        <h3 class="card-label font-weight-bolder text-dark d-block mr-auto">
          Talent History
        </h3>
        <i
          @click.stop="contractsTalantsHistory = false"
          class="flaticon-cancel text-dark font-weight-bold my-4 cursor-pointer"
          style="font-size: 2rem"
        ></i>
      </v-list-item>

      <div
        class="card-body contracts-history-tabs pt-2"
        v-if="contractsTalantsHistory"
      >
        <!--begin::Item-->
        <b-card no-body>
          <b-tabs pills fill active-nav-item-class="primary text-white">
            <b-tab
              title="Nomination"
              active
              :lazy="true"
              @click="getInfiniteHandler"
            >
              <div
                style="padding: 0 16px"
                class="d-flex align-items-start mb-2 justify-content-center"
                v-if="!nominationHistory.length && false"
              >
                <h6 class="text-muted mb-0 mt-4">No history data</h6>
              </div>
              <div class="nomination-list mt-4">
                <div
                  class="d-flex align-items-center"
                  v-for="(nomination, i) in nominationHistory"
                  :key="i"
                  v-bind:class="{ 'mb-3': lastElement(i, nominationHistory) }"
                >
                  <div class="card card-custom card-stretch w-100">
                    <div class="card-body">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <!--begin::Text-->
                        <div
                          class="d-flex flex-column flex-grow-1"
                          v-if="
                            nomination.resource.client &&
                            (nomination.resource.client.first_name ||
                              nomination.resource.client.last_name)
                          "
                        >
                          <span
                            class="font-weight-bold text-dark-75 font-size-lg text-break mb-1"
                          >
                            {{ nomination.resource.client.first_name }}
                            {{ nomination.resource.client.last_name }}
                            <span
                              v-if="nomination.resource.client_project.name"
                            >
                              -
                              <i>
                                {{ nomination.resource.client_project.name }}
                              </i>
                            </span>
                          </span>
                        </div>
                        <!--end::Text-->
                        <b-badge
                          class="mr-1"
                          :variant="
                            nominationActionClass(nomination.resource.status)
                          "
                          >{{
                            nominationAction(nomination.resource.status)
                          }}</b-badge
                        >
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-between mt-5"
                        v-if="nomination.resource.role.name"
                      >
                        <span
                          class="font-size-md grey--text text--darken-1 w-50 mr-5"
                        >
                          <strong>Talent Role:</strong>

                          {{ nomination.resource.role.name }}
                        </span>
                      </div>

                      <div
                        class="d-flex align-items-center justify-content-between mt-5"
                      >
                        <span
                          v-if="nomination.resource.start_date"
                          class="font-size-md grey--text text--darken-1 w-50 mr-5"
                        >
                          <strong>Start Date:</strong>

                          {{ dateFormat(nomination.resource.start_date) }}
                        </span>

                        <span
                          v-if="nomination.resource.end_date"
                          class="font-size-md grey--text text--darken-1 w-50"
                        >
                          <strong>End Date:</strong>

                          {{ dateFormat(nomination.resource.end_date) }}
                        </span>
                      </div>

                      <div
                        class="d-flex align-items-center justify-content-between mt-5"
                      >
                        <span
                          v-if="nomination.resource.full_time.margin"
                          class="font-size-md grey--text text--darken-1 w-50 mr-5"
                        >
                          <strong>Full Time Margin:</strong>

                          {{ nomination.resource.full_time.margin
                          }}<strong>$</strong>
                        </span>

                        <span
                          v-if="nomination.resource.part_time.margin"
                          class="font-size-md grey--text text--darken-1 w-50"
                        >
                          <strong>Part Time Margin:</strong>

                          {{ nomination.resource.part_time.margin
                          }}<strong>$</strong>
                        </span>
                      </div>

                      <div
                        class="d-flex align-items-center justify-content-between mt-5"
                      >
                        <span
                          class="font-size-md grey--text text--darken-1 w-50 mr-5"
                        >
                          <strong>Full Time WHT:</strong>

                          {{ nomination.resource.full_time.wht_value
                          }}<strong>$</strong>
                        </span>

                        <span
                          class="font-size-md grey--text text--darken-1 w-50"
                        >
                          <strong>Part Time WHT:</strong>

                          {{ nomination.resource.part_time.wht_value }}
                          <strong>$</strong>
                        </span>
                      </div>

                      <div
                        class="d-flex align-items-center justify-content-between mt-5"
                      >
                        <span
                          class="font-size-md grey--text text--darken-1 w-50 mr-5"
                        >
                          <strong>Full Time Expected Salary:</strong>

                          {{ nomination.resource.full_time.salary }}
                          <strong>$</strong>
                        </span>

                        <span
                          class="font-size-md grey--text text--darken-1 w-50"
                        >
                          <strong>Part Time Expected Salary:</strong>

                          {{ nomination.resource.part_time.salary }}
                          <strong>$</strong>
                        </span>
                      </div>

                      <div
                        class="d-flex align-items-center justify-content-between mt-5"
                      >
                        <span
                          class="font-size-md grey--text text--darken-1 mr-5"
                        >
                          <strong>Total Full Time Salary with Margin:</strong>

                          {{ nomination.resource.full_time.total_salary }}
                          <strong>$</strong>
                        </span>

                        <span class="font-size-md grey--text text--darken-1">
                          <strong>Total Part Time Salary with Margin: </strong
                          >{{ nomination.resource.part_time.total_salary }}
                          <strong>$</strong>
                        </span>
                      </div>

                      <div
                        class="d-flex justify-content-between align-items-center mt-3"
                      >
                        <span
                          class="font-size-md grey--text text--darken-1 w-50"
                          v-if="nomination.user && nomination.user.name"
                        >
                          <strong>By:</strong>

                          {{ nomination.user.name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <infinite-loading
                  :identifier="infiniteIdNomination"
                  @infinite="infiniteHandler"
                >
                  <div slot="spinner">Loading...</div>
                  <div slot="no-more">
                    <h6 class="text-muted mb-0 mt-4">No more results</h6>
                  </div>
                  <div slot="no-results">
                    <h6 class="text-muted mb-0 mt-4">No history data</h6>
                  </div>
                </infinite-loading>
              </div>
            </b-tab>
            <b-tab
              title="Contracts"
              :lazy="true"
              @click="getInfiniteHandlerContract"
            >
              <div
                style="padding: 0 16px"
                class="d-flex align-items-start mb-2 justify-content-center"
                v-if="!contractsHistory.length && false"
              >
                <h6 class="text-muted mb-0 mt-4">No history data</h6>
              </div>
              <div class="nomination-list mt-4">
                <div
                  class="d-flex align-items-center mt-4"
                  v-for="(contract, i) in contractsHistory"
                  :key="i"
                  v-bind:class="{ 'mb-3': lastElement(i, contractsHistory) }"
                >
                  <TalentContract
                    :contract="contract"
                    @showClientsModal="showClientsModal"
                    @confirmDeleteClientFromUser="confirmDeleteClientFromUser"
                  />
                  <!--end::Info-->
                </div>

                <infinite-loading
                  :key="'infiniteIdContract'"
                  :identifier="infiniteIdContract"
                  @infinite="infiniteHandlerContract"
                >
                  <div slot="spinner">Loading...</div>
                  <div slot="no-more">
                    <h6 class="text-muted mb-0 mt-4">No more results</h6>
                  </div>
                  <div slot="no-results">
                    <h6 class="text-muted mb-0 mt-4">No history data</h6>
                  </div>
                </infinite-loading>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
        <!--end::Item-->

        <div
          class="d-flex align-items-start my-3 justify-content-center"
          v-if="false"
        >
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8"
            :class="{
              'spinner spinner-light spinner-right': false,
            }"
            :disabled="true"
          >
            Load more
          </button>
        </div>
      </div>
    </v-navigation-drawer>
    <div>
      <AddClientToTalentModal
        :contracts="profile.contracts || []"
        :activeClient="activeClient"
      >
        <template #default="{ on }">
          <span ref="clients-modal" v-on="on"></span>
        </template>
      </AddClientToTalentModal>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import BasicInfoCard from "@/components/talent-profile/BasicInfoCard.vue";
import SalaryAndAvailability from "@/components/talent-profile/SalaryAndAvailability.vue";
import TalentWizard from "@/components/talent-profile/TalentWizard.vue";
import TalentBio from "@/components/talent-profile/TalentBio.vue";
import InfiniteLoading from "vue-infinite-loading";
import Swal from "sweetalert2";
import AddClientToTalentModal from "@/components/talent-profile/modals/AddClientToTalentModal.vue";
import TalentContract from "@/components/talent-profile/TalentContract.vue";

export default {
  name: "TalentsProfile",
  components: {
    BasicInfoCard,
    SalaryAndAvailability,
    TalentWizard,
    TalentBio,
    InfiniteLoading,
    AddClientToTalentModal,
    TalentContract,
  },
  data() {
    return {
      contractsTalantsHistory: false,
      profileId: null,
      nominationHistory: [],
      nominationPage: 1,
      contractsHistory: [],
      contractsPage: 1,
      activeClient: null,
      infiniteIdNomination: +new Date(),
      infiniteIdContract: +new Date(),
    };
  },
  created() {
    this.handleGettingProfileData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Talent Profile" },
    ]);
  },
  watch: {
    contractsTalantsHistory(newValue) {
      if (newValue) {
        this.nominationHistory = [];
        this.contractsHistory = [];
        this.nominationPage = 1;
        this.contractsPage = 1;
      }
    },
  },
  computed: {
    ...mapGetters({
      profile: "talentProfile/getProfileData",
    }),
  },
  methods: {
    ...mapActions({
      getProfileData: "talentProfile/getProfileData",
      getNominationHistory: "talentProfile/getNominationHistory",
      getTalentContractHistory: "talentProfile/getTalentContractHistory",
      deleteClientFromTalent: "talentProfile/deleteClientFromTalent",
    }),
    showClientsModal(contract) {
      if (contract) {
        this.activeClient = JSON.parse(JSON.stringify(contract));
      } else {
        this.activeClient = null;
      }
      this.$refs["clients-modal"].click();
    },
    confirmDeleteClientFromUser({ client_project_id, contract_id }) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            client_project_id,
            talent_id: this.$route.params.id,
            contract_id,
          };
          this.deleteClientFromTalent(payload);
        }
      });
    },
    lastElement(i, arr) {
      if (i === arr.length - 1) {
        return false;
      } else {
        return true;
      }
    },
    dateFormat(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    nominationAction(action) {
      if (action === "nominated") {
        return "Nominated";
      } else if (action === "interview_requested") {
        return "Interview Requested";
      } else if (action === "not_interested") {
        return "Not Interested";
      } else if (action === "expired") {
        return "Expired Nomination";
      } else if (action === "pending") {
        return "Pending";
      } else if (action === "future") {
        return "Future";
      }
    },
    nominationActionClass(action) {
      if (action === "nominated") {
        return "success";
      } else if (action === "interview_requested") {
        return "info";
      } else if (action === "not_interested") {
        return "warning";
      } else if (action === "expired") {
        return "danger";
      } else if (action === "pending") {
        return "secondary";
      } else if (action === "future") {
        return "primary";
      }
    },
    handleGettingProfileData() {
      if (this.$route.params.id) {
        this.profileId = this.$route.params.id;
        this.getProfileData(this.profileId);
      }
    },
    infiniteHandlerContract($state) {
      this.getTalentContractHistory({
        talent_id: this.profileId,
        page: this.contractsPage,
      }).then((data) => {
        if (data.data.length > 0) {
          this.contractsPage += 1;
          this.contractsHistory = [
            ...this.contractsHistory,
            ...data.data,
          ].filter((history) => history.id);
          $state.loaded();
        } else {
          this.contractsHistory = [
            ...this.contractsHistory,
            ...data.data,
          ].filter((history) => history.id);
          $state.complete();
        }
      });
    },
    getInfiniteHandlerContract() {
      this.contractsHistory = [];
      this.contractsPage = 1;
      this.infiniteIdContract += 1;

      /*       this.getTalentContractHistory({
        talent_id: this.profileId,
        page: this.contractsPage,
      }).then((data) => {
        if (data.meta.last_page !== data.meta.current_page) {
          this.contractsPage += 1;
          this.contractsHistory = [...this.contractsHistory, ...data.data];
        } else {
          this.contractsHistory = [...this.contractsHistory, ...data.data];
        }
      }); */
    },
    infiniteHandler($state) {
      this.getNominationHistory({
        talent_id: this.profileId,
        page: this.nominationPage,
      }).then((data) => {
        if (data.data.length > 0) {
          this.nominationPage += 1;
          this.nominationHistory = [
            ...this.nominationHistory,
            ...data.data,
          ].filter((history) => history.resource.id);
          $state.loaded();
        } else {
          this.nominationHistory = [
            ...this.nominationHistory,
            ...data.data,
          ].filter((history) => history.resource.id);
          $state.complete();
        }
      });
    },
    getInfiniteHandler() {
      this.nominationHistory = [];
      this.nominationPage = 1;
      this.infiniteIdNomination += 1;

      /*       this.getNominationHistory({
        talent_id: this.profileId,
        page: this.nominationPage,
      }).then((data) => {
        if (data.meta.last_page !== data.meta.current_page) {
          this.nominationPage += 1;
          this.nominationHistory = [...this.nominationHistory, ...data.data];
        } else {
          this.nominationHistory = [...this.nominationHistory, ...data.data];
        }
      }); */
    },
    getJobTitle(type) {
      const jobs = [
        {
          text: "Remote",
          value: "remote",
        },
        {
          text: "Onsite - Iqama",
          value: "onsite-accommodation",
        },
        {
          text: "Onsite",
          value: "onsite",
        },
        {
          text: "Onsite - Business Visa",
          value: "onsite-business-visa",
        },
        {
          text: "Hybrid",
          value: "hybrid",
        },
      ];

      return jobs.find((j) => j.value == type).text;
    },
  },
};
</script>

<style>
.v-navigation-drawer--temporary {
  z-index: 96 !important;
}

.history-fixed-button {
  display: flex;
  position: fixed;
  top: 60%;
  right: 0;
  transform: rotate(90deg) scale(1.1);
  transform-origin: right top;
}

.contracts-history {
  min-width: 460px;
}

.v-navigation-drawer .card.card-custom > .card-body {
  padding: 1rem !important;
}

.contracts-history-tabs .nav.nav-pills.nav-fill {
  padding: 0;
}

.contracts-history-tabs .card {
  border: none;
}

.contracts-history-tabs .nav.nav-pills .nav-link {
  color: #181c32;
  background-color: #d1d3e0;
  font-weight: 600;
}

.contracts-history-tabs .primary {
  background-color: #fff;
}

.nomination-list {
  height: 80vh;
  overflow-y: auto;
}

.nomination-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.nomination-list::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.nomination-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: darkgrey;
}
</style>

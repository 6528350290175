<template>
  <div class="card card-custom card-stretch w-100">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <!--begin::Text-->
        <div class="d-flex flex-column flex-grow-1">
          <span
            v-if="contract.name"
            class="font-weight-bold text-dark-75 font-size-lg text-break mb-1"
          >
            {{ contract.name }}
          </span>
          <span
            v-if="contract.project_name"
            class="text-muted font-weight-bold text-break clinet-to-talent__email-text"
          >
            {{ contract.project_name }}
          </span>
        </div>

        <div
          v-if="
            contract.renewal_state != null &&
            contract.renewal_state != 'renewed'
          "
          class="d-flex align-items-center mx-2"
          style="height: 2.5rem; width: 2.5rem"
        >
          <img
            v-b-tooltip.hover.top="`In contract renewal cycle`"
            src="@/assets/images/renewed.png"
            width="100%"
            alt="in renewal cycle"
          />
        </div>

        <a
          :href="contract.clickup_task_url"
          v-if="contract.clickup_task_url"
          target="_blank"
        >
          <img
            class="d-flex align-items-start mr-2 ml-1"
            style="height: 3rem; width: 3rem"
            src="@/assets/images/clickup.png"
            alt="clickup"
          />
        </a>

        <div class="d-flex align-items-start">
          <b-badge
            class="mr-1"
            :variant="contractStatus(contract.status)"
            v-if="contract.status"
          >
            {{
              contract.status == "deleted"
                ? "Terminated"
                : capitalizeFirstLetter(contract.status)
            }}
          </b-badge>
        </div>

        <div class="d-flex align-items-start" style="height: 3rem">
          <b-dropdown
            variant="outline-info"
            right
            no-caret
            toggle-class="p-2 ml-3"
            menu-class="p-0"
          >
            <template #button-content>
              <i class="flaticon-more px-0"></i>
            </template>
            <b-dropdown-item
              href="#"
              v-if="
                !(contract.status == 'ended' || contract.status == 'deleted') &&
                contract.renewal_state == null
              "
              @click.prevent="showClientsModal(contract)"
              >Edit</b-dropdown-item
            >
            <b-dropdown-item
              :href="`/dashboard/contract/${contract.id}`"
              target="_blank"
              v-if="contract.renewal_state != null"
              >View Renewal details</b-dropdown-item
            >
            <!-- TODO: add delete action and show confirmation popup first => use Swal -->
            <b-dropdown-item
              href="#"
              v-if="
                !(contract.status == 'ended' || contract.status == 'deleted')
              "
              @click.prevent="confirmDeleteClientFromUser()"
              >Terminate</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="handleVeiwHistory">
              {{ veiwHistory ? "Hide History" : "View History" }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!--end::Text-->
      </div>
      <div class="d-flex align-items-center mt-5">
        <span
          v-if="contract.start_date"
          class="font-size-md grey--text text--darken-1 w-50 mr-5"
        >
          <strong>Start Date:</strong>

          {{ contract.start_date }}
        </span>

        <span
          v-if="contract.end_date"
          class="font-size-md grey--text text--darken-1 w-50"
        >
          <strong>End Date:</strong>

          {{ contract.end_date }}
        </span>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-3">
        <span class="font-size-md grey--text text--darken-1">
          <strong>Job Type:</strong>

          <span
            v-if="contract.job_type"
            class="info--text font-weight-bold"
            color="info"
            outlined
            text-color="info"
          >
            {{ getJobTitle(contract.job_type) }}
          </span>
          <span v-else> - </span>
        </span>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-3">
        <span
          v-if="contract.contract_type"
          class="font-size-md grey--text text--darken-1 w-50"
        >
          <strong>Contract Type:</strong>

          <span
            class="info--text font-weight-bold"
            color="info"
            outlined
            text-color="info"
          >
            {{
              contract.contract_type == 1
                ? "Part time"
                : contract.contract_type == 2
                ? "Full time"
                : "Monthly Hours"
            }}
          </span>
        </span>
        <span
          v-if="contract.renewal_type"
          class="font-size-md grey--text text--darken-1 w-50"
        >
          <strong>Renewal Type:</strong>

          <span
            class="info--text font-weight-bold"
            color="info"
            outlined
            text-color="info"
          >
            {{ contract.renewal_type }}
          </span>
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3">
        <span
          v-if="contract.account_manager"
          class="font-size-md grey--text text--darken-1 w-100"
        >
          <strong>Account Manager:</strong>

          <span
            class="info--text font-weight-bold"
            color="info"
            outlined
            text-color="info"
          >
            {{ contract.account_manager.name }}
          </span>
        </span>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-3">
        <span class="font-size-md grey--text text--darken-1 w-50">
          <strong>Monthly Salary:</strong>

          <span
            class="info--text font-weight-bold"
            color="info"
            outlined
            text-color="info"
          >
            {{ contract.monthly_salary }}$
          </span>
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3">
        <span class="font-size-md grey--text text--darken-1 w-50">
          <strong>Margin:</strong>

          <span
            class="info--text font-weight-bold"
            color="info"
            outlined
            text-color="info"
          >
            {{ contract.salary.margin }}$
          </span>
        </span>
        <span class="font-size-md grey--text text--darken-1 w-50">
          <strong>WHT:</strong>

          <span
            class="info--text font-weight-bold"
            color="info"
            outlined
            text-color="info"
          >
            {{ contract.salary.wht_value }}$
          </span>
        </span>
      </div>

      <!--       <div class="d-flex justify-content-between align-items-center mt-3">
         <span class="font-size-md grey--text text--darken-1 w-50">
          <strong>Action:</strong>

          <span
            class="info--text font-weight-bold"
            color="info"
            outlined
            text-color="info"
          >
            {{ capitalizeFirstLetter(contract.event) }}
          </span>
        </span>
      </div> -->

      <div class="d-flex justify-content-between align-items-center mt-3">
        <span
          class="font-size-md grey--text text--darken-1 w-50 mr-5"
          v-if="contract.created_at"
        >
          <strong>At:</strong>
          {{ contract.created_at }}
        </span>

        <span
          v-if="contract.user && contract.user.name"
          class="font-size-md grey--text text--darken-1 w-50"
        >
          <strong>By:</strong>
          {{
            contract.user?.type == "system"
              ? "Product"
              : capitalizeFirstLetter(contract.user.type)
          }}
          {{ contract.user?.type == "system" ? "" : contract.user.name }}
        </span>
      </div>

      <div class="d-flex mt-5 contract-log" v-if="veiwHistory">
        <div class="d-flex">
          <img
            style="height: 2rem; width: 2rem"
            class="me-2"
            src="@/assets/images/icons/process.png"
          />
          <strong>Contract Log</strong>
        </div>
        <div class="d-flex mt-5" v-if="contractHistoryloading">
          <div class="contract-log__loading">Loading...</div>
        </div>
        <div class="d-flex mt-5" v-else>
          <v-stepper
            vertical
            outlined
            :step="contractHistory.length + 1"
            class="w-100"
          >
            <!--             <v-stepper-step :complete="true" :step="1">
              <div>
                <div class="d-flex">
                  <strong class="mr-3">
                    {{
                      contract.event == "deleted"
                        ? "Terminated"
                        : capitalizeFirstLetter(contract.event)
                    }}
                    by
                  </strong>

                  <span class="font-size-md grey--text text--darken-1">
                    {{ contract.created_at }}
                  </span>
                </div>
                <div
                  class="d-flex mt-2"
                  v-if="contract.user && contract.user.name"
                >
                  <span class="font-size-md grey--text text--darken-1">
                    {{
                      contract.user?.type == "system"
                        ? "Product"
                        : capitalizeFirstLetter(contract.user.type)
                    }}

                    {{
                      contract.user?.type == "system"
                        ? ""
                        : ": " + contract.user.name
                    }}
                  </span>
                </div>
                <div
                  class="d-flex flex-column mt-2"
                  v-if="contract.event != 'created'"
                >
                  <div
                    class="font-size-md grey--text text--darken-1 mb-3"
                    :key="val"
                    v-for="(val, key) in contract.new_values"
                    style="width: 400px; line-height: 22px;"
                  >
                    Field <strong>{{ convertUnderscoreToSpace(key) }}</strong
                    >, oldValue{{ key.includes("date") ? " on" : "" }}:
                    <strong>{{
                      contract.old_values[key]
                        ? key == "contract_type"
                          ? mapContractType(contract.old_values[key])
                          : key == "job_type"
                          ? getJobTitle(contract.old_values[key])
                          : contract.old_values[key]
                        : "-"
                    }}</strong>
                    , New value{{ key.includes("date") ? " on" : "" }}:
                    <span
                      class="info--text font-weight-bold"
                      color="info"
                      outlined
                      text-color="info"
                      >{{
                        key == "contract_type"
                          ? mapContractType(val)
                          : key == "job_type"
                          ? getJobTitle(val)
                          : val
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </v-stepper-step> -->

            <v-stepper-step
              :complete="true"
              v-for="(c, i) in contractHistory"
              :key="i"
              :step="i + 1"
            >
              <div style="width: 400px; line-height: 22px">
                <div class="d-flex">
                  <strong class="mr-3">
                    {{
                      c.event == "deleted"
                        ? "Terminated"
                        : capitalizeFirstLetter(c.event)
                    }}
                    by
                  </strong>

                  <span class="font-size-md grey--text text--darken-1">
                    {{ c.created_at }}
                  </span>
                </div>
                <div class="d-flex mt-2" v-if="c.user && c.user.name">
                  <span class="font-size-md grey--text text--darken-1">
                    {{
                      c.user?.type == "system"
                        ? "Product"
                        : capitalizeFirstLetter(c.user.type)
                    }}
                    {{ c.user?.type == "system" ? "" : ": " + c.user.name }}
                  </span>
                </div>
                <div
                  class="d-flex flex-column mt-2"
                  v-if="c.event != 'created'"
                >
                  <div
                    class="font-size-md grey--text text--darken-1 mb-3"
                    :key="val"
                    v-for="(val, key) in c.new_values"
                  >
                    Field <strong>{{ convertUnderscoreToSpace(key) }}</strong
                    >, oldValue{{ key.includes("date") ? " on" : "" }}:
                    <strong>{{
                      c.old_values[key]
                        ? key == "contract_type"
                          ? mapContractType(c.old_values[key])
                          : key == "job_type"
                          ? getJobTitle(c.old_values[key])
                          : c.old_values[key]
                        : "-"
                    }}</strong>
                    , New value{{ key.includes("date") ? " on" : "" }}:
                    <span
                      class="info--text font-weight-bold"
                      color="info"
                      outlined
                      text-color="info"
                      >{{
                        key == "contract_type"
                          ? mapContractType(val)
                          : key == "job_type"
                          ? getJobTitle(val)
                          : val
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </v-stepper-step>
          </v-stepper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    contract: {
      type: Object,
    },
  },
  data() {
    return {
      contractHistory: [],
      veiwHistory: false,
      contractHistoryloading: true,
    };
  },
  methods: {
    ...mapActions({
      getContractHistory: "talentProfile/getContractHistory",
    }),
    showClientsModal($event) {
      this.$emit("showClientsModal", $event);
    },
    confirmDeleteClientFromUser() {
      let payload = {
        client_project_id: this.contract.client_id,
        contract_id: this.contract.id,
      };
      this.$emit("confirmDeleteClientFromUser", payload);
    },
    convertUnderscoreToSpace(inputString) {
      return inputString.replace(/_/g, " ");
    },
    mapContractType(contract_type) {
      return contract_type == 1
        ? "Part time"
        : contract_type == 2
        ? "Full time"
        : "Monthly Hours";
    },
    handleVeiwHistory() {
      if (this.veiwHistory) {
        this.veiwHistory = false;
        return;
      }

      this.veiwHistory = true;
      this.contractHistoryloading = true;
      this.getContractHistory({
        talent_id: this.$route.params.id,
        resource_id: this.contract.id,
        page: 1,
      })
        .then((result) => {
          this.contractHistory = result.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.contractHistoryloading = false;
        });
    },
    getJobTitle(type) {
      const jobs = [
        {
          text: "Remote",
          value: "remote",
        },
        {
          text: "Onsite - Iqama",
          value: "onsite-accommodation",
        },
        {
          text: "Onsite",
          value: "onsite",
        },
        {
          text: "Onsite - Business Visa",
          value: "onsite-business-visa",
        },
        {
          text: "Hybrid",
          value: "hybrid",
        },
      ];

      return jobs.find((j) => j.value == type).text;
    },
    capitalizeFirstLetter(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } else {
        return "";
      }
    },
    contractStatus(status) {
      if (status === "future") {
        return "info";
      } else if (status === "active") {
        return "success";
      } else if (status === "ended") {
        return "danger";
      } else if (status === "deleted") {
        return "warning";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-log__loading {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.contract-log {
  flex-direction: column;
}
</style>

<style>
.v-stepper--vertical {
  padding-bottom: 1rem;
}
</style>

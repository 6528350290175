<template>
  <div>
    <h4
      class="font-weight-bolder text-dark mb-4 d-flex align-items-center justify-content-between"
    >
      Experience
      <b-button
        size="sm"
        variant="outline-info"
        class="px-2 ml-3"
        @click="showExperienceFormModal(null)"
        :disabled="profile.experiences.length >= 25"
        ><i class="fas fa-plus-circle px-0"></i
      ></b-button>
    </h4>

    <div class="wizard-timeline" v-if="profile.experiences.length">
      <div
        class="wizard-timeline__item"
        v-for="experience in profile.experiences"
        :key="experience.id"
      >
        <div
          class="card py-4 px-5 shadow-xs flex-grow-1"
          :class="{
            'wizard-timeline__border': experienceHasAnEmptyRow.find(
              (e) => e.id === experience.id
            ),
          }"
        >
          <div class="d-flex justify-content-between">
            <div>
              <div class="grey--text mb-1">
                (<span v-if="experience.start_month !== null">
                  {{ getMonthName(experience.start_month) }}
                  {{ experience.start_year }} -
                </span>
                <span v-else> - - </span>
                <span v-if="experience.currently_work_in == 1">Present</span>
                <span
                  v-if="
                    experience.currently_work_in == 0 &&
                    experience.end_month !== null
                  "
                  >{{ getMonthName(experience.end_month) }}
                  {{ experience.end_year }}</span
                >
                <span v-else-if="experience.currently_work_in == 0">-</span>
                )
              </div>

              <h4 class="mb-2">
                <span v-if="experience.title">{{ experience.title }} </span>
                <span
                  class="wizard-timeline__item__highlighted-text font-size-lg grey--text text--darken-1"
                  v-if="experience.company"
                >
                  {{ experience.company }}
                </span>
              </h4>
            </div>
            <div class="ml-4 d-flex align-items-center">
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                v-if="
                  experienceHasAnEmptyRow.find((e) => e.id === experience.id)
                "
              >
                Required
                <v-avatar right>
                  <v-icon>report_problem</v-icon>
                </v-avatar>
              </v-chip>
              <b-dropdown
                variant="outline-info"
                right
                no-caret
                toggle-class="p-2 ml-3"
                menu-class="p-0"
              >
                <template #button-content>
                  <i class="flaticon-more px-0"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  @click.prevent="showExperienceFormModal(experience)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click.prevent="confirmDeleteProject(experience.id)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
          <p class="mb-0" v-if="experience.description">
            {{ experience.description }}
          </p>
        </div>
      </div>
    </div>

    <h6 class="text-muted mb-0" v-else>No Data</h6>

    <ExperienceFormModal
      :activeExperience="activeExperience"
      :experienceHasAnEmptyRow="experienceHasAnEmptyRow"
    >
      <template #default="{ on }">
        <span ref="experience-modal" v-on="on"></span>
      </template>
    </ExperienceFormModal>
  </div>
</template>

<script>
import ExperienceFormModal from "@/components/talent-profile/modals/ExperienceFormModal.vue";
import Swal from "sweetalert2";
import MONTHS from "@/constants/months";
export default {
  name: "Experience",
  components: { ExperienceFormModal },
  props: {
    profile: {
      required: true,
    },
    experienceHasAnEmptyRow: {
      type: Array,
    },
  },
  data() {
    return {
      activeExperience: null,
    };
  },
  methods: {
    getMonthName(monthId) {
      return MONTHS.find((month) => month.id === monthId)?.name;
    },
    showExperienceFormModal(experience) {
      if (experience) {
        this.activeExperience = JSON.parse(JSON.stringify(experience));
      } else {
        this.activeExperience = null;
      }
      this.$refs["experience-modal"].click();
    },
    confirmDeleteProject(experienceId) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            experienceId,
            talent_id: this.$route.params.id,
          };
          this.$store.dispatch("talentProfile/deleteTalentExperience", payload);
        }
      });
    },
  },
};
</script>

const AVAILABILITY_OPTIONS = [
  { id: 0, name: "unavailable" },
  { id: 1, name: "immediately" },
  { id: 2, name: "in 1 week" },
  { id: 3, name: "in 2 weeks" },
  { id: 4, name: "in 3 weeks" },
  { id: 5, name: "in 4 weeks" },
  { id: 6, name: "in 5 weeks" },
  { id: 7, name: "in 6 weeks" },
  { id: 8, name: "in 7 weeks" },
  { id: 9, name: "in 8 weeks" },
  { id: 10, name: "in 9 weeks" },
  { id: 11, name: "in 10 weeks" },
];

export default AVAILABILITY_OPTIONS;
